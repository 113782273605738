
















































































































































































import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { apPaymentServices } from "@/services/apPayment.service";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { settingsServices } from "@/services/settings.service";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class PphCustomer extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  moment = moment;
  page = 1 as number;
  limit = 10 as number;
  dataListBranch = [] as DataWarehouseBranch[];
  totalElements = 0 as number;
  dataSource = [] as any[];
  dataListCustomer = [] as ContactData[];
  dataListPphType = [] as any[];
  loading = {
    find: false as boolean,
    branch: false as boolean,
    customerName: false as boolean,
    pphType: false as boolean,
    download: false as boolean,
  };
  downloadParams = {
    companyName:
      this.$store.state.authStore.authData.companyName ||
      "PT. SATRIA PIRANTI PERKASA",
    branch: "ALL",
    customerName: "ALL",
    pphType: "ALL",
    transactionDateFrom: "ALL",
    transactionDateTo: "ALL",
    search: "",
  };
  columnsTable = [
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branch",
      width: 200,
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_customer_tax_registration_number"),
      dataIndex: "taxRegistrationNumber",
      key: "customerTaxRegistrationNumber",
      width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_invoice_amount"),
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_pph_input_date"),
      dataIndex: "inputDate",
      key: "pphInputDate",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_pph_form_date"),
      dataIndex: "pphFormDate",
      key: "pphFormDate",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_pph_number"),
      dataIndex: "pphNumber",
      key: "pphNumber",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_pph_code"),
      dataIndex: "taxCode",
      key: "pphCode",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_pph_rate"),
      dataIndex: "taxRate",
      key: "pphRate",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_pph_amount"),
      dataIndex: "pphAmount",
      key: "pphAmount",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
  ] as any;
  formRules = {
    branch: {
      label: "lbl_branch",
      name: "lbl_branch",
      placeholder: "lbl_branch_placeholder",
      decorator: ["branch"],
    },
    customerName: {
      label: "lbl_customer_name",
      name: "lbl_customer_name",
      placeholder: "lbl_customer_name_placeholder",
      decorator: ["customerName"],
    },
    transactionDateFrom: {
      label: "lbl_transaction_date_from",
      name: "lbl_transaction_date_from",
      placeholder: "lbl_transaction_date_from_placeholder",
      decorator: ["transactionDateFrom"],
    },
    transactionDateTo: {
      label: "lbl_transaction_date_to",
      name: "lbl_transaction_date_to",
      placeholder: "lbl_transaction_date_to_placeholder",
      decorator: ["transactionDateTo"],
    },
    pphType: {
      label: "lbl_pph_type",
      name: "lbl_pph_type",
      placeholder: "lbl_pph_type_placeholder",
      decorator: ["pphType"],
    },
  };
  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "pphCustomer" });
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  download() {
    let params = {
      params: `${this.downloadParams.companyName},${this.downloadParams.branch},${this.downloadParams.customerName},${this.downloadParams.pphType},${this.downloadParams.transactionDateFrom},${this.downloadParams.transactionDateTo}`,
    } as RequestQueryParamsModel;
    if (this.downloadParams.search) params.search = this.downloadParams.search;
    this.loading.download = true;
    apPaymentServices
      .downloadPPhCustomer(params)
      .then(dataBlob => {
        if (dataBlob) {
          const url = window.URL.createObjectURL(new Blob([dataBlob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "pph_customer_report.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .finally(() => (this.loading.download = false));
  }

  clear() {
    this.form.resetFields();
  }
  getCustomer(value: string): void {
    let params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `customer~true_AND_active~true`,
    };
    if (value) {
      params.search += `_AND_firstName~*${value}*_OR_lastName~*${value}*`;
    }
    this.loading.customerName = true;
    contactServices
      .listContactData(params)
      .then(data => {
        this.dataListCustomer = data.data;
      })
      .finally(() => (this.loading.customerName = false));
  }
  mounted() {
    this.getCustomer("");
    this.getListOfWarehouseBranch("");
    this.getPphType("");
  }

  getPphType(value: string) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: "taxType~INCOME_TAX_RECEIVABLES",
    };
    if (value) params.search += `_AND_taxId~${value}`;
    this.loading.pphType = true;
    settingsServices
      .listOfTax(params, "")
      .then(response => {
        this.dataListPphType = response.data;
      })
      .finally(() => (this.loading.pphType = false));
  }

  searchData(value: string, type: "branch" | "customerName" | "pphType") {
    switch (type) {
      case "branch":
        this.getListOfWarehouseBranch(value);
        break;
      case "customerName":
        this.getCustomer(value);
        break;
      case "pphType":
        this.getPphType(value);
        break;
      default:
        break;
    }
  }
  assignSearch(key, value, operator): string {
    if (key === "branch" && value) {
      this.downloadParams.branch = this.dataListBranch.find(
        item => item.id === value
      )?.name as string;
      return operator + `branchId~${value}`;
    } else if (key === "customerName" && value) {
      this.downloadParams.customerName = this.dataListCustomer.find(
        item => item.id == value
      )?.firstName as string;
      return operator + `customerId~${value}`;
    } else if (key === "pphType" && value) {
      this.downloadParams.pphType = value;
      return operator + `taxId~${value}`;
    } else if (key === "transactionDateFrom" && value) {
      this.downloadParams.transactionDateFrom =
        moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `pphFormDate>=${moment(value)
          .set({ hour: 0, minute: 0, second: 0 })
          .format()}`
      );
    } else if (key === "transactionDateTo" && value) {
      this.downloadParams.transactionDateTo =
        moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `pphFormDate<=${moment(value)
          .set({ hour: 23, minute: 59, second: 59 })
          .format()}`
      );
    } else return "";
  }

  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }

  getListOfWarehouseBranch(value) {
    let params = {
      limit: 10,
      page: 0,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel;
    if (value) params.search = `name~*${value}*`;
    this.loading.branch = true;
    return logisticServices
      .listWarehouseBranch(params, "")
      .then((res: any) => {
        this.dataListBranch = res.data;
      })
      .finally(() => (this.loading.branch = false));
  }
  async findData(pagination): Promise<void> {
    try {
      this.form.validateFields((err, res) => {
        if (err) return;
        const params: RequestQueryParamsModel = {
          page: pagination ? this.page - 1 : 0,
          limit: this.limit,
          sorts: "createdDate:desc",
        };
        for (const key in this.downloadParams) {
          if (key != "companyName") {
            this.downloadParams[key] = "ALL";
          }
        }
        if (this.dynamicSearch(res)) {
          params.search = this.dynamicSearch(res);
        }
        this.downloadParams.search = params.search as string;
        this.getData(params);
      });
    } catch (error) {
      this.$message.error(Messages.PROCESS_FAIL);
    }
  }
  getData(params) {
    apPaymentServices.listPPhCustomer(params).then(res => {
      this.dataSource = res.data;
      this.totalElements = res.totalElements;
    });
  }
  responsePageSizeChange(response: ResponsePagination) {
    this.limit = response.size;
    this.page = 1;
    this.findData(true);
  }

  responseCurrentPageChange(response: ResponsePagination) {
    this.page = response.page;
    this.findData(true);
  }
}
