var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_pph_customer_report") } },
    [
      _c(
        "a-row",
        [
          _c(
            "a-form",
            {
              attrs: {
                layout: "vertical",
                form: _vm.form,
                "label-col": { span: 8 },
                "wrapper-col": { span: 14 }
              }
            },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.branch.label) }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.branch.decorator,
                                  expression: "formRules.branch.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.$t(_vm.formRules.branch.name),
                                placeholder: _vm.$t(
                                  _vm.formRules.branch.placeholder
                                ),
                                showSearch: true,
                                "option-filter-prop": "children",
                                "filter-option": _vm.filterOption,
                                loading: _vm.loading.branch,
                                allowClear: true
                              },
                              on: {
                                search: function(value) {
                                  return _vm.searchData(value, "branch")
                                }
                              }
                            },
                            _vm._l(_vm.dataListBranch, function(data, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: data.id } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s("" + data.name) + " "
                                        )
                                      ]),
                                      _vm._v(" " + _vm._s("" + data.name) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.customerName.label)
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.customerName.decorator,
                                  expression: "formRules.customerName.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.customerName.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.customerName.placeholder
                                ),
                                showSearch: true,
                                allowClear: true,
                                loading: _vm.loading.customer,
                                "option-filter-prop": "children",
                                "filter-option": _vm.filterOption
                              },
                              on: {
                                search: function(value) {
                                  return _vm.searchData(value, "customerName")
                                }
                              }
                            },
                            _vm._l(_vm.dataListCustomer, function(data, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: data.id } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s("" + data.firstName) +
                                            " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s("" + data.firstName) + " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.pphType.label) }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.pphType.decorator,
                                  expression: "formRules.pphType.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.pphType.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.pphType.placeholder
                                ),
                                showSearch: true,
                                allowClear: true,
                                loading: _vm.loading.pphType,
                                "option-filter-prop": "children",
                                "filter-option": _vm.filterOption
                              },
                              on: {
                                search: function(value) {
                                  return _vm.searchData(value, "pphType")
                                }
                              }
                            },
                            _vm._l(_vm.dataListPphType, function(data, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: data.id } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s("" + data.code) + " "
                                        )
                                      ]),
                                      _vm._v(" " + _vm._s("" + data.code) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: { label: _vm.$t("lbl_transaction_date_from") }
                        },
                        [
                          _c("a-date-picker", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value:
                                  _vm.formRules.transactionDateFrom.decorator,
                                expression:
                                  "formRules.transactionDateFrom.decorator"
                              }
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              name: _vm.$t("lbl_transaction_date_from"),
                              placeholder: _vm.$t(
                                "lbl_transaction_date_from_placeholder"
                              ),
                              format: _vm.DEFAULT_DATE_FORMAT
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: _vm.$t("lbl_transaction_date_to") } },
                        [
                          _c("a-date-picker", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value:
                                  _vm.formRules.transactionDateTo.decorator,
                                expression:
                                  "formRules.transactionDateTo.decorator"
                              }
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              name: _vm.$t("lbl_transaction_date_to"),
                              placeholder: _vm.$t(
                                "lbl_transaction_date_to_placeholder"
                              ),
                              format: _vm.DEFAULT_DATE_FORMAT
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    {
                      staticClass: "mt-2 mb-2",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end"
                      },
                      attrs: { span: 24 }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: {
                            width: "180px",
                            "margin-right": "1rem"
                          },
                          attrs: { type: "danger" },
                          on: { click: _vm.clear }
                        },
                        [_vm._v(" " + _vm._s(this.$t("lbl_reset")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: {
                            width: "180px",
                            "margin-right": "1rem"
                          },
                          attrs: { type: "primary", loading: _vm.loading.find },
                          on: {
                            click: function($event) {
                              return _vm.findData(false)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(this.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { staticClass: "mt-4", attrs: { span: 24 } },
            [
              _c("TableCustom", {
                attrs: {
                  idtable: "table1",
                  dataSource: _vm.dataSource,
                  columns: _vm.columnsTable,
                  onSelectChange: null,
                  selectedRowKeys: null,
                  scroll: { x: "calc(700px + 50%)" },
                  paginationcustom: true,
                  defaultPagination: false
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.totalElements,
                  pageSizeSet: _vm.limit,
                  idPagination: "pagination1"
                },
                on: {
                  "response-pagesize-change": _vm.responsePageSizeChange,
                  "response-currentpage-change": _vm.responseCurrentPageChange
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { staticClass: "mt-3", attrs: { span: 12, align: "end" } },
            [
              _c(
                "a-tag",
                {
                  staticStyle: { "font-size": "13px" },
                  attrs: { color: "grey" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("lbl_total_found")) +
                      " : " +
                      _vm._s(_vm.totalElements) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { staticClass: "mt-2", attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-button",
                {
                  staticStyle: { "margin-right": "0.5rem" },
                  attrs: {
                    type: "primary",
                    loading: _vm.loading.download,
                    disabled: !_vm.dataSource.length
                  },
                  on: { click: _vm.download }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }